import {
    Button,
    Card, Col,
    Form,
    Image, Modal, Row, Select, Switch, Tag,
    Tooltip, Typography, Input
} from "antd";
import {
    CloseOutlined, DisconnectOutlined, DownloadOutlined,
    LoadingOutlined, SettingOutlined, WarningOutlined,
} from "@ant-design/icons";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    createEvolutionInstance,
    deleteEvolutionInstance,
    getEvolutionInstance,
    getEvolutionById,
    updateEvolutionInstance
} from "../../services/evolution";

export default function EvolutionIntegration({icon, label, assistantID, messageApi}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);

    const [form] = Form.useForm();
    const onlyWithoutTag = Form.useWatch('onlyWithoutTag', form);
    const onlyWithTags = Form.useWatch('onlyWithTags', form);

    useEffect(() => {
        setLoading(true)
        getEvolutionInstance({assistantID}).then(({data}) => {
            if (data.length === 0)
                return
            setData(data[0])
        }).catch((error) => {
            console.log(error)
            messageApi.error("Erro ao buscar integração com site.");
        }).finally(() => {
            setLoading(false)
        });
    }, [assistantID, messageApi])

    const install = useCallback(async () => {
        setButtonLoading(true)
        await createEvolutionInstance({
            assistantID: parseInt(assistantID),
            onlyWithoutTag: false,
            active: true,
        }).then(({data}) => {
            setData(data);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao instalar integração com Evolution.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [assistantID, messageApi]);

    const uninstall = useCallback(async () => {
        setButtonLoading(true);
        await deleteEvolutionInstance(data.id).then(() => {
            setData(undefined);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao remover integração com Evolution.");
        }).finally(() => {
            setButtonLoading(false)
        });
    }, [data, messageApi]);

    const update = useCallback(async (newData) => {
        setButtonLoading(true);
        await updateEvolutionInstance(data.id, {...data, ...newData}).then(({data: updatedData}) => {
            setData({...updatedData});
            setConfigOpen(false);
            messageApi.success("Atualizado com sucesso!");
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao atualizar.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [data, messageApi]);

    const save = useCallback(async (values) => {
        await form.validateFields().then(() => {
            update(values);
        }).catch((err) => {
            console.log(err)
        })

    }, [form, update]);

    const handleConfigOpen = () => {
        form.setFieldsValue(data);
        setConfigOpen(true);
    }

    const handleConfigClose = () => {
        form.resetFields()
        setConfigOpen(false)
    }

    return (
        <>
            <Modal title="Configurar Chat Externo"
                   open={configOpen}
                   cancelText={'Cancelar'}
                   confirmLoading={buttonLoading}
                   okText={'Salvar'}
                   onOk={form.submit}
                   onCancel={handleConfigClose}>
                <br/>
                <Row gutter={[24]}>
                    <Col span={24}>
                        <Form layout={'vertical'} form={form} onFinish={save}>
                            <Form.Item label={'Responder apenas sem etiqueta'}
                                       name={'onlyWithoutTag'}
                                       valuePropName={"checked"}
                                       help={'Responder apenas a contatos que não tiverem nenhum etiqueta.'}
                                       initialValue={false}>
                                <Switch disabled={onlyWithTags}/>
                            </Form.Item>

                            <Form.Item label={'Responder com tags especificas'}
                                       name={'onlyWithTags'}
                                       valuePropName={"checked"}
                                       help={'Responder apenas a contatos tiverem etiquetas especificas.'}
                                       initialValue={false}>
                                <Switch disabled={onlyWithoutTag}/>
                            </Form.Item>

                            {!onlyWithoutTag && <Form.Item label={'Tags'}
                                                           name={'tags'}
                                                           help={'Preencha caso deseje que que o bot só responda clientes com determinadas etiquetas.'}>
                                <Select
                                    mode="tags"
                                    style={{width: '100%'}}
                                    placeholder="Digite tags"
                                    tokenSeparators={[',']}
                                    open={false}
                                />
                            </Form.Item>}
                            <Form.Item label={'Definir tag quando enviado pelo app'}
                                       name={'setTagFromApp'}
                                       help={'Preencha caso deseje que o bot defina uma etiqueta quando alguem mandar uma mensagem atraves do app do whatsapp.'}>
                                <Input/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Card
                loading={loading}
                title={<Typography.Title level={5} ellipsis={{tooltip: label}}>{icon} {label}</Typography.Title>}
                extra={
                    <Button type={data ? 'default' : 'primary'}
                            loading={loading || buttonLoading}
                            danger={!!data}
                            onClick={() => data ? uninstall() : install()}
                            icon={data ? <CloseOutlined/> : <DownloadOutlined/>}
                    >{loading ? undefined : data ? 'Remover' : 'Instalar'}</Button>
                }
                actions={data ? [
                    <Tooltip title={'Em breve será possível desconectar'}>
                        <DisconnectOutlined/>
                    </Tooltip>,
                    <SettingOutlined onClick={handleConfigOpen}/>,
                ] : undefined}>
                <Configuration setData={setData} data={data} messageApi={messageApi}/>
            </Card>
        </>
    )
}

function Configuration({data, messageApi, setData}) {
    const [checking, setChecking] = useState(false);

    const isConnected = useMemo(() => data?.status?.instance?.state === 'open', [data]);

    useEffect(() => {
        if (!data?.id || data?.status?.instance?.state === 'open')
            return;

        let time = 3000;
        let limit = 40;
        let i = 0;
        const interval = setInterval(() => {
            setChecking(true)
            getEvolutionById(data.id).then(({data}) => {
                setData(data);
                if (data?.status?.instance?.state === 'open') {
                    clearInterval(interval);
                    setChecking(false);
                }
            }).catch(e => {
                console.log(e);
                messageApi.error("Falha ao checar status");
            })

            i++;
            if (i > limit) clearInterval(interval);
        }, time)

        return () => clearInterval(interval);
    }, [data?.id, data?.status?.state, messageApi]);

    const Disconnected = () =>
        <Row justify={'center'} wrap>
            <Tag color={'error'} icon={<DisconnectOutlined/>} style={{
                justifySelf: 'center',
                margin: 0,
                marginBottom: 12
            }}>{checking ? "Verificando..." : "Desconectado"} {checking && <LoadingOutlined/>}</Tag>
            <Col span={24}/>
            <Image src={data.qrCodeImage} height={150}/>
        </Row>

    const Connected = () =>
        <Typography.Text>
            Evolution configurada e operando normalmente
        </Typography.Text>

    if (!data) return <Typography>
        Configure o Whatsapp com sua instancia da Evolution.
    </Typography>

    return (
        <>{isConnected ? <Connected/> : <Disconnected/>}</>
    )
}
